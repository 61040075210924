.App {
  margin: 60px auto;
  text-align: center;
  max-width: 960px;
}

.App h1 {
  font-weight: bold;
  color: #0061a9;
  font-size: 30px;
}

.App p {
  font-size: 18px;
  color: #4a4a4a;
  line-height: 1.3;
}

.App .btn {
  margin-top: 40px;
}

.App .js-form-wrapper legend{
  width: 200px;
}

.language-wrapper {
  text-align: center;
  margin-top: 60px;
  margin-bottom: -30px;
}

.language-img {
  width: 60px;
  height: 40px;
  filter: grayscale(1);
  transition: .7s;
}

.language-img.active {
  filter: grayscale(0);
}