body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  margin: 60px auto;
  text-align: center;
  max-width: 960px;
}

.App h1 {
  font-weight: bold;
  color: #0061a9;
  font-size: 30px;
}

.App p {
  font-size: 18px;
  color: #4a4a4a;
  line-height: 1.3;
}

.App .btn {
  margin-top: 40px;
}

.App .js-form-wrapper legend{
  width: 200px;
}

.language-wrapper {
  text-align: center;
  margin-top: 60px;
  margin-bottom: -30px;
}

.language-img {
  width: 60px;
  height: 40px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  -webkit-transition: .7s;
  transition: .7s;
}

.language-img.active {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
